// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-analytics-js": () => import("./../../../src/pages/Analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-compass-js": () => import("./../../../src/pages/Compass.js" /* webpackChunkName: "component---src-pages-compass-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logger-js": () => import("./../../../src/pages/Logger.js" /* webpackChunkName: "component---src-pages-logger-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/Profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-summary-js": () => import("./../../../src/pages/Summary.js" /* webpackChunkName: "component---src-pages-summary-js" */)
}

